import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import router from './router'
import '@css/index.css'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.$message = Element.Message
Vue.use(Element)

new Vue({
	router,
    render: h => h(App),
}).$mount('#app')
